import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { Container } from '@mui/material';

import './App.css';
import ReportForm from './components/ReportForm';
import QrGeneratorForm from './components/QrGeneratorForm';
import Header from './components/Header';
import { useLocalization } from './hooks/useLocalization';

function App() {
  const [lang, setLang] = React.useState('');

  const localizedStrings = useLocalization();

  return (
    <div className="App">

      <BrowserRouter>
        <Container maxWidth="lg">
          <Header changeLangRef={setLang} />
          <Routes>
            <Route path="/qrcode-generator" element={<QrGeneratorForm />} />
            <Route path="/u/:companyUid" element={<ReportForm />} />
            <Route path="/" element={<ReportForm />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </div>
  );
}

export default App;
