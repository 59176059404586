import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Theme {
        color: {
            primary: string;
            primaryDark: string;
            font: string;
        };
    }
    interface ThemeOptions {
        color?: {
            primary?: string;
            primaryDark?: string;
            font?: string;
        };
    }
}

const theme = createTheme(
    {
        color: {
            primary: "#8dc342",
            primaryDark: "#6F9B31",
            font: "#333",
        },
    }
);

export default theme;