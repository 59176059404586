const translations = {
    ReportForm: {
        title: "Absenz melden",
        firstname: "Vorname",
        surname: "Name",
        emailaddress: "E-Mail",
        phonenumber: "Telefon",
        ahvnumber: "AHV Nummer",
        VacationRadio: {
            label: "Hat die Person vor oder nach der Absenz Ferien?",
            unknown: "Unbekannt",
            yes: "Ja",
            no: "Nein",
        },
        ContactCheckbox: {
            label: "Bitte um Rückruf vor der Kontaktaufnahme mit der abwesenden Person.",
            contactphonenumber: "Kontaktnummer",
        },
        submit: "Absenden",
        ThankYou: {
            main: "Die Abwesenheitsmeldung wurde erfolgreich übermittelt.",
        },
        Errors: {
            uidError: "Ihre URL ist ungültig. Bitte verwenden Sie den QR Code.",
            InvalidInput: "Bitte füllen Sie dieses Feld aus.",
            InvalidEmailInput: "Bitte geben Sie eine gültige E-Mail Adresse an.",
            Error400: "Die Anfrage ist ungültig. Bitte wenden Sie sich an Joblife Services.",
            Error404: "Die Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es später erneut.",
            Error500: "Bei der Verarbeitung Ihrer Daten tratt ein Fehler auf. Bitte versuchen Sie es später erneut.",
            ErrorUnknown: "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
        }
    },
    QrGeneratorForm: {
        title: "QR-Code Generator",
        companyUid: "Firmen-UID",
        generateButton: "Generieren",
        linkToWebapp: "Applink",
        copyLinkTitle: "Kopieren",
        qrCode: "QR-Code",
        downloadQrCode: "QR-Code Herunterladen",
        snackbarText: "In die Zwischenablage kopiert!"
    }
}

export default translations;