import { FormControl, Select, SelectChangeEvent, MenuItem, Box, Container, Toolbar } from '@mui/material';
import logo from '../logo.png';
import { useLocalization } from '../hooks/useLocalization';

const Header = (props: any) => {
    const setLangRef = props.changeLangRef;

    const localizedStrings = useLocalization();

    const changeLanguage = (event: SelectChangeEvent) => {
        var newLang = (event.target?.value as string)?.toLocaleLowerCase();

        localizedStrings.setLanguage(newLang);
        setLangRef(newLang);
    };

    return (
        <header className="App-header" >
            <Container maxWidth={false} disableGutters>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        <img src={logo} className="App-logo" alt="logo" />
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <FormControl className="langSelect" sx={{ color: 'inherit' }}>
                            <Select
                                value={localizedStrings.getLanguage()}
                                onChange={changeLanguage}
                                sx={{ color: 'inherit' }}
                            >
                                {
                                    localizedStrings.getAvailableLanguages().map(curLang => {
                                        return (<MenuItem value={curLang} key={curLang}>{curLang.toUpperCase()}</MenuItem>);
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Toolbar>
            </Container>
        </header>
    );
}

export default Header;